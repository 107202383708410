import React, { useState, useCallback } from 'react'
import { useClasses, clearForm } from '../lib/ReactUtils'
import Loader from './Loader'
import PostContact from '../lib/PostContact'
import { useToast } from '../sections/Toast'

export default function ContactForm({className = '', messageLabel = 'Message', messagePrefix = ''}) {
  const root = useClasses()
  const toast = useToast()
  const [errors, setErrors] = useState({name:[], email:[], phone:[], message:[]})
  const [submitting, setSubmitting] = useState(false)
  const [result, setResult] = useState(null)

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault()
    const _errors = {name:[], email:[], phone:[], message:[]}
    const target = e.currentTarget
    const name = target[0].value
    const email = target[1].value
    const phone = target[2].value
    const message = target[3].value
    if (name.length === 0) _errors.name.push('Please enter your fullname')
    else if (name.length < 5) _errors.name.push('Name too short')
    else if (!name.includes(' ')) _errors.name.push('Please include first and last name')
    if (email.length === 0) _errors.email.push('Please enter your email')
    else if (email.length < 5) _errors.email.push('Email too short')
    else if (!email.includes('@')) _errors.email.push('Invalid email')
    if (phone.length < 5) _errors.phone.push('Please enter your phone in case we need to reach you')
    else if (!/^[0-9\s+\-\s]/.test(phone)) _errors.email.push('Invalid phone number')
    if (message.length < 5) _errors.message.push('Please type your message')
    setErrors(_errors)
    setResult(null)
    if (
      _errors.name.length || 
      _errors.email.length || 
      _errors.phone.length || 
      _errors.message.length
    ) return
    setSubmitting(true)
    const array = name.split(' ')
    const first = array.shift()
    const last = array.join(' ')
    const result = await PostContact({first, last, email, phone, message: messagePrefix + message})
    setSubmitting(false)
    if (result) {
      toast.show('Success! We will be in touch in one businesss day at the most', 'success')
      clearForm(target)
    }
    else toast.show('Failed! Please email us at info@oneoffice.ca', 'error')
    setResult(result)
  }, [messagePrefix, setErrors, setResult, setSubmitting])

  return (
    <form ref={root} className={'bg-white ' + className} onSubmit={handleSubmit}>
      <div>
        <input type='text' placeholder=' ' name='name' disabled={submitting}/>
        <label htmlFor='name'>Fullname</label>
        { errors.name.map((e, index) => <div key={index} className='error'>{e}</div>) }
      </div>
      <div>
        <input type='email' placeholder=' ' name='email' disabled={submitting}/>
        <label htmlFor='email'>Email</label>
        { errors.email.map((e, index) => <div key={index} className='error'>{e}</div>) }
      </div>
      <div>
        <input type='text' placeholder=' ' name='phone' disabled={submitting}/>
        <label htmlFor='phone'>Phone</label>
        { errors.phone.map((e, index) => <div key={index} className='error'>{e}</div>) }
      </div>
      <div>
        <textarea type='text' placeholder=' ' name='message' rows={6} disabled={submitting}/>
        <label htmlFor='message'>{messageLabel}</label>
        { errors.message.map((e, index) => <div key={index} className='error'>{e}</div>) }
      </div>
      { result === true ? <div className='text-green-500 font-bold text-large mt-2 leading-snug'>Success! We will be in touch in one business day at the most</div> : null }
      { result === false ? <div className='text-green-500 font-bold text-large mt-2 leading-snug'>Failed! Please email us at info@oneoffice.ca</div> : null }
      <button type='submit' className='button font-bold mt-2'>
        {submitting ? <Loader className='inline mr-3'/> : null}
        Submit
      </button>
     </form>
  )
}
