import * as React from "react"
import { useClasses } from '../lib/ReactUtils'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import ContactForm from '../components/ContactForm'
import '../styles/TwoCols'
import MAIL from '../images/contact/mail.svg'

export default function ContactPage() {
  const root = useClasses()
  return (
    <Layout short>
      <SEO title='Contact Us'/>
      <main className="main" ref={root}>

        <span className="heading-separator"/>
        <h1 className="title">Contact Us</h1>
        <h2 className="subtitle">Would love to hear from you</h2>

        <div className="twocols my-10">
          <div className="twocols-col-image">
            <img src={MAIL} alt="Mail"/>
          </div>
          <div className="twocols-col-text-left">
            <ContactForm className='md:max-w-md'/>
          </div>
        </div>

        <p className='text-medium font-light mb-5 text-gray-600'>
          We will respond back in one business day at the most. 
          Please check your spam folder. 
          Your email will only be used to respond to your query.
        </p>
      </main>
    </Layout>
  )
}

